@media only screen and (max-width: 640px) {
  .menu_title {
    display: none;
  }
}

.dark_theme_line {
  border-right: 1px solid #333 !important;
}

#microphone-btn:active {
  cursor: pointer;
}
#microphone-btn:disabled {
  cursor: not-allowed;
  pointer-events: all !important;
  background: #a8a8a8 !important;
}
